import { useRoutes } from 'react-router-dom';
import router from 'src/router';

import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';

import { CssBaseline } from '@mui/material';
import ThemeProvider from './theme/ThemeProvider';
import { AuthProvider } from './Authentication/AuthContext';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import '../src/mystyle.css';

function App() {
  const content = useRoutes(router);

  return (
    <AuthProvider>
      <ToastContainer />
      <ThemeProvider>
        {/* <LocalizationProvider dateAdapter={AdapterDateFns}> */}
        <CssBaseline />
        {content}
        {/* </LocalizationProvider> */}
      </ThemeProvider>
    </AuthProvider>
  );
}
export default App;
