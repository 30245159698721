// import PropTypes from 'prop-types';
// import { Scrollbar } from 'react-scrollbars-custom';

// import { Box, useTheme } from '@mui/material';

// const ScrollBar = ({ className, children, ...rest }) => {
//   const theme = useTheme();

//   return (
//     <Scrollbar
//       trackXProps={{
//         style: { width: 0, display: 'none' }
//       }}
//       trackYProps={{
//         style: { height: 0, display: 'none' }
//       }}
//       renderThumbvertical={() => {
//         return (
//           <Box
//             sx={{
//               width: 5,
//               background: `${theme.colors.alpha.black[10]}`,
//               borderRadius: `${theme.general.borderRadiusLg}`,
//               transition: `${theme.transitions.create(['background'])}`,

//               '&:hover': {
//                 background: `${theme.colors.alpha.black[30]}`
//               }
//             }}
//           />
//         );
//       }}
//       {...rest}
//     >
//       {children}
//     </Scrollbar>
//   );
// };

// ScrollBar.propTypes = {
//   children: PropTypes.node,
//   className: PropTypes.string
// };

// export default ScrollBar;


// import PropTypes from 'prop-types';
// import { Scrollbar } from 'react-scrollbars-custom';
// import { Box, useTheme } from '@mui/material';

// const ScrollBar = ({ className, children, ...rest }) => {
//   const theme = useTheme();

//   return (
//     <Scrollbar
//       trackXProps={{
//         style: { width: 0, display: 'none' }
//       }}
//       trackYProps={{
//         style: { height: 0, display: 'none' }
//       }}
//       renderer={(props) => {
//         const { elementRef, ...otherProps } = props; // Ensure unsupported props are not passed to the DOM
//         return <div {...otherProps} ref={elementRef} />;
//       }}
//       thumbYProps={{
//         renderer: ({ elementRef, style, ...restThumbProps }) => (
//           <Box
//             ref={elementRef}
//             sx={{
//               ...style,
//               width: 5,
//               background: `${theme.colors.alpha.black[10]}`,
//               borderRadius: `${theme.general.borderRadiusLg}`,
//               transition: `${theme.transitions.create(['background'])}`,

//               '&:hover': {
//                 background: `${theme.colors.alpha.black[30]}`
//               }
//             }}
//             {...restThumbProps}
//           />
//         )
//       }}
//       {...rest}
//     >
//       {children}
//     </Scrollbar>
//   );
// };

// ScrollBar.propTypes = {
//   children: PropTypes.node,
//   className: PropTypes.string
// };

// export default ScrollBar;


// old scrollbar, bcs of above one chat page not fetching messages while scrolling

import PropTypes from 'prop-types';
import { Scrollbars } from 'react-custom-scrollbars-2';

import { Box, useTheme } from '@mui/material';

const Scrollbar = ({ className, children, ...rest }) => {
  const theme = useTheme();

  return (
    <Scrollbars
      autoHide
      renderThumbVertical={() => {
        return (
          <Box
            sx={{
              width: 5,
              background: `${theme.colors.alpha.black[10]}`,
              borderRadius: `${theme.general.borderRadiusLg}`,
              transition: `${theme.transitions.create(['background'])}`,

              '&:hover': {
                background: `${theme.colors.alpha.black[30]}`
              }
            }}
          />
        );
      }}
      {...rest}
    >
      {children}
    </Scrollbars>
  );
};

Scrollbar.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string
};

export default Scrollbar;


